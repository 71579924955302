import React, { FC } from 'react';
import { graphql } from 'gatsby';
import WorldOverview from '../components/WorldOverview';
import { OverviewWorldAndMetaData } from '../graphQL/overviewData';

const AboutMeWorld: FC<OverviewWorldAndMetaData> = ({ data }) => (
    <WorldOverview
        data={{
            allMarkdownRemark: data.allMarkdownRemark,
            extraImages: data.extraImages,
        }}
        title="about_me"
        metadata={data.metadata.edges[0].node.childMarkdownRemark.frontmatter}
    />
);

export const query = graphql`
    query upcomingWorldShows {
        allMarkdownRemark(
            filter: { frontmatter: { genericAM: { title: { ne: null } } } }
            sort: { fields: fields___date }
        ) {
            ...aboutMeOverviewData
        }
        extraImages: allFile(
            filter: { absolutePath: { regex: "extra/about_me/i" } }
        ) {
            edges {
                node {
                    childMarkdownRemark {
                        frontmatter {
                            extraImages {
                                photos {
                                    photo_url
                                    photo_alt
                                }
                            }
                        }
                    }
                }
            }
        }
        metadata: allFile(filter: { absolutePath: { regex: "/meta_data/i" } }) {
            edges {
                node {
                    childMarkdownRemark {
                        frontmatter {
                            nlMeta {
                                aboutMe {
                                    description
                                    title
                                    keywords {
                                        keyword
                                    }
                                    photo_url
                                }
                            }
                            enMeta {
                                aboutMe {
                                    description
                                    title
                                    keywords {
                                        keyword
                                    }
                                    photo_url
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default AboutMeWorld;
